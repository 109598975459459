import React from "react"
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom"
import {useDispatch} from "react-redux"

export function withRouter(Child) {
    return React.forwardRef((props, ref) => {
        const location = useLocation()
        const navigate = useNavigate()
        const params = useParams()
        const dispatch = useDispatch()

        const [searchParams] = useSearchParams()

        return <Child
            {...props}
            ref={ref}
            navigate={navigate}
            dispatch={dispatch}
            location={location}
            match={{params}}
            search={searchParams}
        />
    })
}