import Index from '../views/Index'
import {useRoutes} from "react-router-dom";

const Router = ({allRoutes}) => {
    const routes = useRoutes([
        {
            path: '/',
            element: <Index/>,
            children: [...allRoutes]
        },
    ])

    return routes
}

export default Router