import React from "react"
import axios from 'axios'
import qs from 'qs'

const pending = new Map()
/**
 * 添加请求
 * @param {Object} config
 */
const addPending = config => {
    if (!!config.repeatRequest && config.repeatRequest === true) {
        return false
    }

    const url = [
        config.method,
        config.url,
        JSON.stringify(config.params),
        JSON.stringify(config.data)
    ].join('&')
    config.cancelToken =
        config.cancelToken ||
        new axios.CancelToken(cancel => {
            if (!pending.has(url)) {
                // 如果 pending 中不存在当前请求，则添加进去
                pending.set(url, cancel)
            }
        })
}
/**
 * 移除请求
 * @param {Object} config
 */
const removePending = config => {
    if (!!config.repeatRequest && config.repeatRequest === true) {
        return false
    }

    const url = [
        config.method,
        config.url,
        JSON.stringify(config.params),
        JSON.stringify(config.data)
    ].join('&')
    if (pending.has(url)) {
        // 如果在 pending 中存在当前请求标识，需要取消当前请求，并且移除
        const cancel = pending.get(url)
        cancel(url)
        pending.delete(url)
    }
}
/**
 * 清空 pending 中的请求（在路由跳转时调用）
 */
const clearPending = () => {
    for (const [url, cancel] of pending) {
        cancel(url)
    }
    pending.clear()
}

const instance = axios.create({
    timeout: 10 * 1000 // 设置超时时间10s
})

instance.defaults.headers.post['Content-Type'] =
    'application/x-www-form-urlencoded'

const httpCode = {
    200: '请求成功',
    201: '创建成功',
    204: '请求成功',
    304: '资源没有被修改。可以使用缓存的版本',
    400: '错误请求',
    401: '认证失败',
    403: '服务器拒绝',
    404: '请求资源未找到',
    405: '不被允许的方法',
    415: '不支持的媒体类型',
    422: '数据验证失败',
    429: '请求过于频繁，请稍后重试',
    500: '内部服务器错误',
    501: '服务器不支持该请求中使用的方法',
    502: '网关错误',
    504: '网关超时'
}

/**
 * 请求拦截
 */
instance.interceptors.request.use(
    config => {
        removePending(config) // 在请求开始前，对之前的请求做检查取消操作
        addPending(config) // 将当前请求添加到 pending 中

        return new Promise((resolve, reject) => {
            config.params = config.params
            resolve(config)
        })
    },
    error => {
        return Promise.reject(error)
    }
)

/**
 * 响应拦截
 */
instance.interceptors.response.use(
    response => {
        removePending(response)
        if (response.status === 200 || response.status === 204) {
            return Promise.resolve(response.data)
        } else {
            return Promise.reject({
                code: response.status,
                message: response.status in httpCode ? httpCode[response.status] : ''
            })
        }
    },
    error => {
        if (error.response) {
            const tips =
                error.response.status in httpCode
                    ? httpCode[error.response.status]
                    : ''
            return Promise.reject({
                code: error.response.status,
                message: tips
            })

        } else {
            return Promise.reject({
                code: 500,
                message: '请求失败，请稍后重试!'
            })
        }
    }
)

/**
 * get 请求
 * @param url 请求url
 * @param config 配置参数
 * @returns {Promise<unknown>}
 */
export const Get = async (url, config = {}) => {
    return await new Promise((resolve, reject) => {
        instance({
            method: 'get',
            url,
            ...config
        })
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

/**
 * post 请求
 * @param url 请求url
 * @param data 提交数据
 * @param config 配置参数
 * @returns {Promise<unknown>}
 */
export const Post = async (url, data, config = {}) => {
    if (config.hasOwnProperty("enctype") === false || config.enctype === "default") {
        data = qs.stringify(data)
    }

    const typeArr = {
        json: 'application/json',
        multipart: 'multipart/form-data',
        default: 'application/x-www-form-urlencoded'
    }

    config['headers'] = {
        'Content-Type': config.hasOwnProperty('enctype') && typeArr.hasOwnProperty(config.enctype) ? typeArr[config.enctype] : typeArr.default
    }

    config['onUploadProgress'] = config.hasOwnProperty("onUploadProgress") ? config.onUploadProgress : false

    return await new Promise((resolve, reject) => {
        instance({
            method: 'post',
            url,
            data,
            ...config
        })
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

/**
 * update 请求
 * @param url
 * @param data
 * @param config
 * @returns {Promise<unknown>}
 */
export const Update = async (url, data, config = {}) => {
    const typeArr = {
        json: 'application/json',
        multipart: 'multipart/form-data',
        default: 'application/x-www-form-urlencoded'
    }

    config['headers'] = {
        'Content-Type': typeArr.default
    }

    if (config.hasOwnProperty('enctype') && typeArr.hasOwnProperty(config.enctype)) {
        config['headers']['Content-Type'] = typeArr[config.enctype]
    } else {
        data = qs.stringify(data)
    }

    return await new Promise((resolve, reject) => {
        instance({
            method: 'patch',
            url,
            data,
            ...config
        })
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

/**
 * delete 请求
 * @param url
 * @param data
 * @returns {Promise<void>}
 * @constructor
 */
export const Delete = async (url, data = {}) => {
    const config = {
        headers: {
            'Content-Type': 'text/plain'
        }
    }

    return await new Promise((resolve, reject) => {
        instance({
            method: 'delete',
            url,
            data,
            ...config
        })
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                if (!!error.code && error.code === 204) {
                    resolve(error)
                } else {
                    reject(error)
                }
            })
    })
}

const Request = {
    post: Post,
    get: Get,
    update: Update,
    delete: Delete
}
export default Request