import React from "react";
import {Button, Dialog, Empty, List, NavBar, Radio, Toast} from "antd-mobile";
import wechatPay from "../assets/images/wechat_pay.svg";
import alipay from "../assets/images/alipay.svg";
import {withRouter} from "../router/withRouter";
import Request from '../network/Request'

class Index extends React.Component {
    constructor(props) {
        let {
            search
        } = props

        super(props);

        let amount = search.get('amount') || '0'
        let orderId = search.get('orderid') || null
        let navbar = search.get('navbar') && search.get('navbar') === 'false' ? false : true
        let error = !orderId ? true : false

        this.state = {
            navbar,
            error,
            type: 1,
            amount,
            orderId
        }
    }

    pay() {
        var system = {};
        var p = navigator.platform;
        system.win = p.indexOf("Win") == 0;
        system.mac = p.indexOf("Mac") == 0;
        if (system.win || system.mac) {//如果是电脑
            Toast.show({
                content: '请在手机上打开此页面',
                afterClose: () => {
                    console.log('after')
                },
            })
        } else {
            let type = this.state.type
            if (type === 1) {
                this.wechatPay()
            } else {
                this.alipay()
            }
        }
    }

    alipay() {
        let params = {
            tradeMode: 2,
            payChannel: 2,
            paymentNo: this.state.orderId
        }
        let toast = Toast.show({
            icon: 'loading',
            content: '加载中',
        })

        Request.post('https://c.api.bjcyppm.com/prepaid-transaction/clientOut/payInfo/orderPay', params, {enctype: 'json'}).then(res => {
            toast.close()
            if (res.code === 200 && !!res.data && !!res.data.payUrl) {
                this.payConfirm()
                window.location.replace(res.data.payUrl)
            } else {
                Toast.show({
                    content: '支付请求失败',
                })
            }

        }).catch((err) => {
            toast.close()
            Toast.show({
                content: '支付请求失败',
            })
        })
    }

    wechatPay() {
        let params = {
            orderid: this.state.orderId,
            amount: this.state.amount
        }
        let toast = Toast.show({
            icon: 'loading',
            content: '加载中',
        })

        Request.post('https://c.api.bjcyppm.com/prepaid-wechat/clientOut/wechat/payGetSchema', params, {enctype: 'json'}).then(res => {
            toast.close()
            if (res.code === 200 && !!res.data && !!res.data.urlScheme) {
                this.payConfirm()
                window.location.href = res.data.urlScheme

            } else {
                Toast.show({
                    content: '支付请求失败',
                })
            }

        }).catch((err) => {
            toast.close()
            Toast.show({
                content: '支付请求失败',
            })
        })
    }

    payConfirm() {
        let image = {
            1: wechatPay,
            2: alipay
        }
        Dialog.confirm({
            content: (
                <div className="d-flex justify-content-between align-items-center flex-column">
                    <img src={image[this.state.type]} style={{height: '30px'}}/>
                    <span className='font-size-14 mt10'>确认是否完成支付</span>
                </div>
            ),
            cancelText: <span className='font-size-16' style={{color: '#333'}}>支付失败</span>,
            confirmText: <span className='font-size-16'>已完成支付</span>,
            onCancel: () => {

            },
            onConfirm: () => {
                window.history.go(-1)
            },
        })
    }

    render() {
        let {
            error,
            type,
            amount
        } = this.state

        return (
            <>
                {error === false && (
                    <div className="body">
                        {this.state.navbar === true && (
                            <NavBar onBack={() => {
                                window.history.back(-1)
                            }}>收银台</NavBar>
                        )}

                        <div
                            className='header box-sizing-border w-100 d-flex justify-content-center align-items-center flex-column px20 py30'>
                            <span className='font-size-14 font-weight-bold'>支付金额</span>
                            <span className='font-size-12 mt20'>北京商务中心区信链科技有限公司</span>
                            <div className='d-flex align-items-baseline font-weight-bold mt10'>
                                <span className='font-size-12'>￥</span>
                                <span className='font-size-26'>{amount}</span>
                            </div>
                        </div>
                        <div className='w-100' style={{background: 'rgb(245, 245, 245)', height: '5px'}}></div>
                        <Radio.Group
                            value={type}
                            onChange={(value) => {
                                this.setState({
                                    type: value
                                })
                            }}
                        >
                            <List header={<span className='font-size-14'>支付方式</span>}>
                                <List.Item>
                                    <Radio value={1} block style={{'--icon-size': '20px'}}>
                                        <div className='d-flex align-items-center ml10'>
                                            <img src={wechatPay} style={{height: '30px'}}/>
                                            <span className="ml10">微信支付</span>
                                        </div>
                                    </Radio>
                                </List.Item>
                                <List.Item>
                                    <Radio value={2} block style={{'--icon-size': '20px'}}>
                                        <div className="d-flex align-items-center ml10">
                                            <img src={alipay} style={{height: '30px'}}/>
                                            <span className="ml10">支付宝支付</span>
                                        </div>
                                    </Radio>
                                </List.Item>
                            </List>
                        </Radio.Group>
                        <div style={{padding: '20px', marginTop: '80px'}}>
                            <Button
                                block
                                className='mt20'
                                color='primary'
                                fill='solid'
                                onClick={() => {
                                    this.pay()
                                }}
                            >
                                立即支付
                            </Button>
                        </div>
                    </div>
                )}
                {error === true && (
                    <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                        <Empty
                            style={{padding: '64px 0'}}
                            imageStyle={{width: 128}}
                            description='订单信息错误'
                        />
                    </div>
                )}
            </>
        )
    }
}

export default withRouter(Index)